import { Col, Row } from "react-bootstrap";
import HomePagePhones from "../assets/images/home_page_phones.png";
import AppleDownload from "../assets/images/apple-download.png";
import GoogleDownload from "../assets/images/google-download.png";

export default function HomeScreen() {
    return (
        <div className="px-5 pt-5">
            <Row>
                <Col sm={12} lg={6} className="">
                    <div className="d-flex align-items-center h-100">
                        <div className="">
                            <div className="">
                                <div>
                                    <h1
                                        className="qp-text-green"
                                        style={{ fontSize: "2.5em" }}
                                    >
                                        <strong>
                                            Simplifying your Payments,
                                            <br /> Empowering your Life!
                                        </strong>
                                    </h1>
                                    <h6>
                                        Receive Money, Make Seamless Bill
                                        Payments, and Effortlessly Transfer
                                        Funds.
                                    </h6>
                                </div>
                            </div>
                            <div className="my-3">
                                <span className="fw-bold">Get the app</span>
                                <div>
                                    <a
                                        href="https://apps.apple.com/gb/app/quickpurse/id6477889197"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            src={AppleDownload}
                                            height="50"
                                            className="mb-2"
                                        />
                                    </a>{" "}
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.temilimited.quickpurse"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            src={GoogleDownload}
                                            height="50"
                                            className="mb-2"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
                <Col sm={12} lg={6}>
                    <Row className="d-flex justify-content-center">
                        <Col lg={10} className="text-center">
                            <img
                                src={HomePagePhones}
                                alt="HomePagePhones"
                                width="100%"
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
}
