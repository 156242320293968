export default function Footer() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return (
        <div className="mt-3">
            <hr className="my-0" />
            <div className="px-5 text-center py-3 qp-text-green">
                Copyright © {currentYear} QuickPurse by Temi Technologies. All
                rights reserved.
            </div>
        </div>
    );
}
