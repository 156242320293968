export default function ContactUsScreen() {
    return (
        <div>
            <div className="py-5 d-flex justify-content-center align-items-center qp-bg-green">
                <div className="">
                    <h1 className="display-4 text-white">Contact Us</h1>
                </div>
            </div>
            <div className="px-5 pt-3 text-center">
                <p>We'd love to hear from you!</p>
                <p>
                    {" "}
                    Feel free to reach out to us at{" "}
                    <a href="mailto:quickpurse@temilimited.com">
                        quickpurse@temilimited.com
                    </a>
                    .
                </p>{" "}
                We are always here to assist you.
            </div>
        </div>
    );
}
