import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
} from "react-router-dom";
import DeletingAccountScreen from "./screens/DeletingAccountScreen";
import HomeScreen from "./screens/HomeScreen";
import Body from "./Body";
import PrivacyPolicyScreen from "./screens/PrivacyPolicyScreen";
import ContactUsScreen from "./screens/ContactUsScreen";
import FAQScreen from "./screens/FAQScreen";
import axios from "axios";
import ConfirmEmailScreen from "./screens/ConfirmEmailScreen";
import CookiePolicy from "./screens/CookiePolicy";

// @ts-ignore
axios.defaults.baseURL = import.meta.env.VITE_BASE_URL;

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" element={<Body />}>
            <Route index element={<HomeScreen />} />
            <Route
                path="/faqs/deleting_account"
                element={<DeletingAccountScreen />}
            />
            <Route
                path="/faqs/privacy_policy"
                element={<PrivacyPolicyScreen />}
            />
            <Route path="/faqs/cookie_policy" element={<CookiePolicy />} />
            <Route path="/contact_us" element={<ContactUsScreen />} />
            <Route path="/faqs" element={<FAQScreen />} />
            <Route
                path="/activate/:uidb/:token"
                element={<ConfirmEmailScreen />}
            />
        </Route>
    )
);

function App() {
    return (
        <>
            <RouterProvider router={router} />
        </>
    );
}

export default App;
